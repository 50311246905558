import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Typography, App } from "antd";
import { deleteEvent } from "api/events";
import styles from './style.module.scss';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useI18n } from "components/I18nProvider";
const { Title } = Typography;
const DangerZone = ({ eventId }) => {
    const { intl } = useI18n();
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleDelete = async () => {
        setLoading(true);
        try {
            await deleteEvent(eventId);
            void message.success(intl.formatMessage({ id: 'eventDeleted' }));
            navigate('/apps/live');
        }
        catch (error) {
            void message.error(intl.formatMessage({ id: 'eventDeleteFailed' }));
        }
        setLoading(false);
    };
    return (_jsxs("div", { className: styles.dangerZone, children: [_jsx(Title, { level: 2, type: "danger", children: intl.formatMessage({ id: 'dangerZone' }) }), _jsx(Divider, { className: styles.divider }), _jsx(Title, { level: 3, children: intl.formatMessage({ id: 'deleteEvent' }) }), _jsx("p", { children: intl.formatMessage({ id: 'deleteEventInfo' }) }), _jsx(Button, { loading: loading, onClick: handleDelete, type: "primary", danger: true, children: intl.formatMessage({ id: 'deleteEvent' }) })] }));
};
export default DangerZone;
