import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Flex, Spin } from "antd";
import { CalendarOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
import { useEffect, useState } from "react";
const renderIcon = (event) => {
    if (event === 'loading') {
        return _jsx(Loading3QuartersOutlined, { className: styles.icon, spin: true });
    }
    else if (event === 'not-found') {
        return _jsx(CloseCircleOutlined, { className: styles.icon });
    }
    else {
        return _jsx(CalendarOutlined, { className: styles.icon });
    }
};
const renderHeading = (event, intl) => {
    if (event === 'loading') {
        return intl.formatMessage({ id: 'loading' });
    }
    else if (event === 'not-found') {
        return intl.formatMessage({ id: 'eventNotFound' });
    }
    else {
        return event.title;
    }
};
const renderBody = (event, intl) => {
    if (event === 'loading') {
        return null;
    }
    else if (event === 'not-found') {
        return null;
    }
    else {
        return (_jsxs("div", { children: [_jsx("p", { children: event.description }), _jsx(Button, { target: "_blank", href: `/apps/live/spa/subtitles/${event.id}`, children: intl.formatMessage({ id: 'subtitlesPage' }) })] }));
    }
};
const EventCard = ({ event }) => {
    const { intl } = useI18n();
    const [loading, setLoading] = useState(true);
    const eventLoaded = event !== "loading" && event !== "not-found";
    useEffect(() => {
        // NOTE: We know this is terrible but Chrome does not support onLoadStart on img element
        // so we have to chain state updates. See https://github.com/facebook/react/issues/20330
        setLoading(true);
    }, [eventLoaded && event.shortSlug]);
    return (_jsx(Card, { children: _jsxs(Flex, { justify: "space-between", children: [_jsxs("div", { className: styles.eventInfo, children: [renderIcon(event), _jsxs("div", { children: [_jsx("h2", { children: renderHeading(event, intl) }), renderBody(event, intl)] })] }), eventLoaded && event.shortSlug !== null &&
                    _jsx(Spin, { spinning: loading, children: _jsx("div", { className: styles.qrCode, children: _jsx("img", { onLoad: () => setLoading(false), src: `${window.origin}/apps/live/api/events/${event.shortSlug}/qrcode`, alt: "Event QR code" }) }) })] }) }));
};
export default EventCard;
