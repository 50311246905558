import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AudioOutlined, UnorderedListOutlined, SettingOutlined } from '@ant-design/icons';
import { fetchEvent } from 'api/events';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { Menu } from "antd";
import EventCard from './EventCard';
import Page from 'components/Page';
import { useRecording } from 'lib/recording';
import styles from './style.module.scss';
const items = [
    {
        label: 'Record',
        key: 'record',
        icon: _jsx(AudioOutlined, {}),
    },
    {
        label: 'Segments',
        key: 'segments',
        icon: _jsx(UnorderedListOutlined, {}),
    },
    {
        label: 'Settings',
        key: 'settings',
        icon: _jsx(SettingOutlined, {}),
    },
];
const EventPage = () => {
    const navigate = useNavigate();
    const recording = useRecording();
    const { params: { subpage } } = useMatch('/apps/live/spa/events/:eventId/:subpage?');
    const { eventId } = useParams();
    const [event, setEvent] = useState('loading');
    useEffect(() => {
        const fetchEventState = async () => {
            if (eventId === undefined) {
                return;
            }
            const event = await fetchEvent(eventId);
            setEvent(event);
        };
        fetchEventState();
    }, []);
    useEffect(() => {
        if (recording.phase === 'in-progress') {
            const preventUnload = (event) => {
                event.preventDefault();
            };
            window.addEventListener('beforeunload', preventUnload);
            return () => {
                window.removeEventListener('beforeunload', preventUnload);
            };
        }
    }, [recording.phase]);
    const handleMenuItemClick = (info) => {
        if (info.key === 'record') {
            navigate(`.`);
        }
        else if (info.key === 'segments') {
            navigate(`segments`);
        }
        else if (info.key === 'settings') {
            navigate(`settings`);
        }
    };
    return (_jsxs(Page, { children: [_jsx(EventCard, { event: event }), event !== 'not-found' && event !== 'loading' && (_jsxs("div", { children: [_jsx(Menu, { className: styles.menu, mode: "horizontal", items: items, disabled: recording.phase !== 'idle' || event.activeSegment !== null, onClick: handleMenuItemClick, selectedKeys: [subpage ?? 'record'] }), _jsx(Outlet, { context: { event, recording, setEvent } })] }))] }));
};
export default EventPage;
