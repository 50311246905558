import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Flex, Form, Input, Space, App } from "antd";
import { updateEvent } from "api/events";
import { useI18n } from "components/I18nProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import slugify from "slugify";
import styles from './style.module.scss';
import { EditOutlined } from "@ant-design/icons";
import { useEventContext } from "pages/EventPage/event-context";
import DangerZone from "./DangerZone";
import { fetchConfig } from "api/config";
const normalizeSlug = (value, mode) => {
    if (value === "") {
        return '';
    }
    return (value.endsWith(' ') || value.endsWith('-')) && mode === 'onChange'
        ? value
        : slugify(value, { lower: true, strict: true });
};
const SettingsSubpage = () => {
    const { intl } = useI18n();
    const { eventId } = useParams();
    const { message } = App.useApp();
    const { event, setEvent } = useEventContext();
    const [urlField, setUrlField] = useState(event.shortSlug === null ? "editing" : "saved");
    const [form] = Form.useForm();
    const eventSlug = Form.useWatch('shortSlug', form);
    const [urlBase, setUrlBase] = useState(intl.formatMessage({ id: 'loading' }));
    if (eventId === undefined) {
        return _jsx("p", { children: intl.formatMessage({ id: 'loading' }) });
    }
    useEffect(() => {
        const fetchConfigState = async () => {
            const config = await fetchConfig();
            setUrlBase(config.shortOrigin);
        };
        void fetchConfigState();
    }, []);
    useEffect(() => {
        if (event.shortSlug !== null) {
            form.setFieldsValue({ shortSlug: event.shortSlug });
        }
    }, [event.shortSlug]);
    const handleSubmit = async (values) => {
        setUrlField("loading");
        if (values.shortSlug !== null) {
            const normalizedSlug = normalizeSlug(values.shortSlug, 'submit');
            const result = await updateEvent(eventId, normalizedSlug);
            if (result === 'already-exists') {
                void message.error(intl.formatMessage({ id: 'eventSlugExists' }));
                setUrlField("editing");
            }
            else {
                void message.success(intl.formatMessage({ id: 'eventSlugSaved' }));
                setEvent({ ...event, shortSlug: result.shortSlug });
                setUrlField("saved");
            }
        }
    };
    return (_jsxs(Card, { title: intl.formatMessage({ id: 'settings' }), children: [_jsx(Form, { layout: "vertical", form: form, initialValues: { shortSlug: null }, className: styles.form, onFinish: handleSubmit, children: _jsx(Form.Item, { className: styles.url, label: intl.formatMessage({ id: 'eventUrl' }), children: _jsxs(Flex, { align: "baseline", children: [_jsx("span", { className: styles.urlBase, children: urlBase }), urlField !== "saved" ? (_jsxs(Space.Compact, { children: [_jsx(Form.Item, { name: "shortSlug", normalize: (value) => normalizeSlug(value, 'onChange'), rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: intl.formatMessage({ id: 'eventSlugRequired' })
                                            }], children: _jsx(Input, {}) }), _jsx(Button, { loading: urlField === "loading", htmlType: "submit", type: "primary", children: intl.formatMessage({ id: 'set' }) })] })) : (_jsxs(Space, { align: "baseline", children: [_jsx(Form.Item, { name: "shortSlug", children: _jsx("span", { className: styles.slug, children: eventSlug }) }), _jsx(EditOutlined, { className: styles.editButton, onClick: () => setUrlField("editing") })] }))] }) }) }), _jsx(DangerZone, { eventId: event.id })] }));
};
export default SettingsSubpage;
